import React from 'react'
import SearchResultItem from '../searchResultItem/SearchResultItem'

function SearchResultList(props) {
    const searchItems = props.results.map((item) =>
        <SearchResultItem key={item.title} result={item} />
    );

    return (
        <div className="w-full px-4 py-4 sm:px-6 lg:px-8">
            <div className="mx-auto">
                <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                    {searchItems}
                </div>
            </div>
        </div>
    )
}

export default SearchResultList
