import React from 'react';

function Checkbox(props) {
    return (
        <div className="relative flex items-start mx-auto w-4/5">
            <div className="flex items-center h-5">
                <input
                    id={props.id}
                    name={props.name}
                    type="checkbox"
                    className="focus:ring-green-gmkhb h-4 w-4 text-green-gmkhb border-gray-300 rounded"
                    onChange={props.onChange}
                />
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={props.id} className="font-medium text-gray-700">
                    {props.name}
                </label>
                <p className="text-gray-500">{props.description}</p>
            </div>
        </div>
    );
}

export default Checkbox;
