export function stringToHTMLObj(text) {
    const htmlObj = {
        __html: text
    };

    return htmlObj;
}

export function dateToString(date) {
    let dateObj = new Date(date);

    let day = ('0' + dateObj.getDate()).slice(-2);
    let month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    let year = dateObj.getFullYear();

    let dateString = day + '.' + month + '.' + year;

    return dateString;
}