import React from 'react'

function InputSubmit(props) {
    return (
        <input
            type="submit"
            className="px-3 py-2 h-11 shadow-sm border-2 rounded-md bg-white border-gray-300 text-green-gmkhb font-medium hover:border-green-gmkhb"
            value={props.name}
        />


    )
}

export default InputSubmit
