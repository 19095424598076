import { React, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import {
    HomeIcon,
    SearchIcon,
    UploadIcon,
    XIcon,
} from '@heroicons/react/outline'
import Logo from './../../images/logo_GMKHB.PNG';

const navigation = [
    { name: 'Startseite', href: '/', icon: HomeIcon, current: false },
    { name: 'GMKHB Durchsuchen', href: '/search', icon: SearchIcon, current: false },
    { name: 'Inhalte hochladen', href: '/upload', icon: UploadIcon, current: false },
]

function Navbar({ sidebarOpen, setSidebarOpen }) {
    return (
        <div className="flex overflow-hidden bg-gray-100">
            {/* Navbar for mobile */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed inset-0 flex z-40 md:hidden"
                    open={sidebarOpen}
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img
                                    className="h-auto w-auto"
                                    src={Logo}
                                    alt="Logo der Green Meeting Know-How Box"
                                />
                            </div>
                            <div className="mt-5 flex-grow flex flex-col">
                                <nav className="flex-1 bg-white space-y-1" aria-label="Sidebar">
                                    {navigation.map((item) => (
                                        <NavLink
                                            key={item.name}
                                            exact to={item.href}
                                            className='group flex items-center px-3 py-2 text-sm font-medium border-l-4 border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                            activeClassName='bg-indigo-50 border-green-gmkhb text-green-gmkhb'
                                        >
                                            <item.icon
                                                className='mr-3 flex-shrink-0 h-6 w-6'
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </NavLink>
                                    ))}
                                    <div className="h-16"></div>
                                </nav>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>
            {/* /End Navbar for mobile */}

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:flex-shrink-0">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4 space-y-5">
                            <img
                                className="h-auto w-auto"
                                src={Logo}
                                alt="Logo der Green Meeting Know-How Box"
                            />
                        </div>
                        <div className="mt-5 flex-grow flex flex-col">
                            <nav className="flex-1 bg-white space-y-1" aria-label="Sidebar">
                                {navigation.map((item) => (
                                    <NavLink
                                        key={item.name}
                                        exact to={item.href}
                                        className='group flex items-center px-3 py-2 text-sm font-medium border-l-4 border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                        activeClassName='bg-indigo-50 border-green-gmkhb text-green-gmkhb'

                                    >
                                        <item.icon
                                            className='mr-3 flex-shrink-0 h-6 w-6'
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </NavLink>
                                ))}

                                <div className="h-16"></div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* /End static sidebar for desktop */}
        </div>
    )
}

export default Navbar;
