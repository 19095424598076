import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="bg-white flex justify-center">
            <div className="max-w-7xl mx-auto py-4 pt-0 px-4 sm:px-6 md:flex md:py-12 md:items-center md:justify-between lg:px-8">
                <div className="mt-8 md:mt-0 md:order-1">
                    <p className="text-center text-base text-gray-400">&copy; <a href="https://imwi.uos.de">Fachgebiet für Informationsmanagement und Wirtschaftsinformatik</a> - Universität Osnabrück- <Link to="/datenschutz">Datenschutzerklärung</Link> - <Link to="/impressum">Impressum</Link></p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
