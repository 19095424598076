import dialog from 'suneditor/src/plugins/modules/dialog'
<script src="https://cdn.jsdelivr.net/npm/suneditor@latest/src/plugins/modules/dialog.js"></script>

const insertPDF = {
    name: 'PDF_einfügen',

    display: 'dialog',

    title: 'PDF einfügen',
    buttonClass: '',
    innerHTML: '<img src="https://img.icons8.com/ios/50/000000/pdf--v1.png" width="20px" height="20px" style="margin: auto;"/>',

    add: function (core) {
        core.addModule([dialog]);

        const context = core.context;
        context.PDF_einfügen = {
            focusElement: null,
            _pdfAnchor: null
        };

        /** link Dialog */
        let pdf_dialog = this.setDialog(core);
        context.PDF_einfügen.modal = pdf_dialog;
        context.PDF_einfügen.focusElement = pdf_dialog.querySelector('._se_pdf_url');

        /** link Controller */
        let pdf_controller = this.setController_PDFButton(core);
        context.PDF_einfügen.pdfController = pdf_controller;
        context.PDF_einfügen._pdfAnchor = null;

        /** add event listeners */
        pdf_dialog.querySelector('form').addEventListener('submit', this.submit.bind(core));
        pdf_controller.addEventListener('click', this.onClick_pdfController.bind(core));

        /** append html */
        context.dialog.modal.appendChild(pdf_dialog);

        /** append controller */
        context.element.relative.appendChild(pdf_controller);

        /** empty memory */
        pdf_dialog = null; 
        pdf_controller = null;
    },

    /** dialog */
    setDialog: function(core) {
        const lang = core.lang;
        const dialog = core.util.createElement('DIV');

        dialog.className = 'se-dialog-content';
        dialog.style.display = 'none';

        let html = '' +
        '<form class="editor_pdf">' +
            '<div class="se-dialog-header">' +
                '<button type="button" data-command="close" class="se-btn se-dialog-close" aria-label="Close" title="' + lang.dialogBox.close + '">' +
                    core.icons.cancel +
                '</button>' +
                '<span class="se-modal-title">PDF einfügen</span>' +
            '</div>' +
            '<div class="se-dialog-body">' +
                '<div class="se-dialog-form">' +
                    '<label>URL zur PDF-Datei</label>' +
                    '<input class="se-input-form _se_pdf_url" type="text" />' +
                '</div>' +
            '</div>' +
            '<div class="se-dialog-footer">' +
                '<button type="submit" class="se-btn-primary" title="' + lang.dialogBox.submitButton + '"><span>' + lang.dialogBox.submitButton + '</span></button>' +
            '</div>' +
        '</form>';

        dialog.innerHTML = html;

        return dialog;
    },

    /** modify controller button */
    setController_PDFButton: function(core) {
        const lang = core.lang;
        const icons = core.icons;
        const pdf_btn = core.util.createElement('DIV');

        pdf_btn.className = 'se-controller se-controller-pdf';
        pdf_btn.innerHTML = '' +
            '<div class="se-arrow se-arrow-up"></div>' +
            '<div class="pdf-content"><span><a target="_blank" href=""></a>&nbsp;</span>' +
                '<div class="se-btn-group">' +
                    '<button type="button" data-command="update" tabindex="-1" class="se-tooltip">' +
                        icons.edit +
                        '<span class="se-tooltip-inner"><span class="se-tooltip-text">' + lang.controller.edit + '</span></span>' +
                    '</button>' +
                    '<button type="button" data-command="delete" tabindex="-1" class="se-tooltip">' +
                        icons.delete +
                        '<span class="se-tooltip-inner"><span class="se-tooltip-text">' + lang.controller.remove + '</span></span>' +
                    '</button>' +
                '</div>' +
            '</div>';

        return pdf_btn;
    },

    // This method is called when the plugin button is clicked.
    // Open the modal window here.
    open: function () {
        // open.call(core, pluginName, isModify)
        this.plugins.dialog.open.call(this, 'PDF_einfügen', 'PDF_einfügen' === this.currentControllerName);
    },

    submit: function(e) {
        this.showLoading();

        e.preventDefault();
        e.stopPropagation();

        const submitAction = function () {
            if (this.context.PDF_einfügen.focusElement.value.trim().length === 0) return false;

            const contextPDF = this.context.PDF_einfügen;
            const url = contextPDF.focusElement.value;

            // When opened for modification "this.context.dialog.updateModal" is true
            if (!this.context.dialog.updateModal) {
                const pdfFrame = this.util.createElement('iframe');
                pdfFrame.src = url;
                pdfFrame.height="1000";
                pdfFrame.width="100%";

                this.insertNode(pdfFrame);

                //this.setRange(pdfFrame.childNodes[0], 0, pdfFrame.childNodes[0], pdfFrame.textContent.length);
            } else {
                contextPDF._pdfAnchor.href = url;

                // set range
                //this.setRange(contextPDF._pdfAnchor.childNodes[0], 0, contextPDF._pdfAnchor.childNodes[0], contextPDF._pdfAnchor.textContent.length);
            }

            // history stack
            this.history.push(false);

            contextPDF.focusElement.value = '';
        }.bind(this);

        try {
            submitAction();
        } finally {
            this.plugins.dialog.close.call(this);
            this.closeLoading();
            this.focus();
        }

        return false;
    },

    // This method is called just before the dialog opens.
    // If "update" argument is true, it is not a new call, but a call to modify an already created element.
    on: function (update) {
        if (!update) {
            this.plugins.PDF_einfügen.init.call(this);
        } else if (this.context.PDF_einfügen._pdfAnchor) {
            // "update" and "this.context.dialog.updateModal" are always the same value.
            // This code is an exception to the "link" plugin.
            this.context.dialog.updateModal = true;
            this.context.PDF_einfügen.focusElement.value = this.context.PDF_einfügen._pdfAnchor.href;
        }
    },

    
    onClick_pdfController: function (e) {
        e.stopPropagation();

        const command = e.target.getAttribute('data-command');
        if (!command) return;

        e.preventDefault();

        if (/update/.test(command)) {
            const contextPDF = this.context.PDF_einfügen;
            contextPDF.focusElement.value = contextPDF._pdfAnchor.href;
            this.plugins.dialog.open.call(this, 'PDF_einfügen', true);
        }
        else {
            /** delete */
            this.util.removeItem(this.context.PDF_einfügen._pdfAnchor);
            this.context.PDF_einfügen._pdfAnchor = null;
            this.focus();

            // history stack
            this.history.push(false);
        }

        this.controllersOff();
    },

    // This method is called when the dialog window is closed.
    // Initialize the properties.
    init: function () {
        const contextPDF = this.context.PDF_einfügen;
        contextPDF.pdfController.style.display = 'none';
        contextPDF._pdfAnchor = null;
        contextPDF.focusElement.value = '';
    }
}

export default insertPDF