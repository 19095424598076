import React, { useState, useEffect } from 'react';
import ContentCard from './../../components/contentCard/ContentCard';
import UserCard from '../../components/userCard/UserCard'
import { fetchLearningContent, fetchPrimaryAuthor, fetchTags, deleteLearningContent } from './../../services/api.js';
import { stringToHTMLObj, dateToString } from './../../services/converter.js';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react'

export default function LearningContent() {
    const { user, getAccessTokenSilently } = useAuth0();
    let history = useHistory();
    
    // States für den User
    const [isAuthor, setIsAuthor] = useState(false)
    const [userAuth0Mail, setUserAuth0Mail] = useState('')
    const [accessToken, setAccessToken] = useState()

    // States für den LearningContent
    const [title, setTitle] = useState('title');
    const [pictureSrc, setPictureSrc] = useState('src');
    const [pictureAlt, setPictureAlt] = useState('alt');
    const [publishedAt, setPublishedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');
    const [contentHTML, setContentHTML] = useState({ __html: '' });
    const [tags, setTags] = useState([]);

    //States für den Autor
    const [authorName, setAuthorName] = useState('name');
    const [location, setLocation] = useState('location');
    const [profilePictureSrc, setProfilePictureSrc] = useState();
    const [email, setEmail] = useState();
    const [website, setWebsite] = useState();
    const [authorAuth0Mail, setAuthorAuth0Mail] = useState('')

    let { slug } = useParams();
    let slugStr = { slug }.slug;

    useEffect(() => {
        fetchContent(slugStr);
        fetchAuthor(slugStr);
        fetchTagList(slugStr);
    }, [slugStr]);

    useEffect(() => {
        if (user !== undefined) {
            setUserAuth0Mail({ user }.user.email)
        }
    }, [user])

    useEffect(() => {
        const getToken = async () => {

            try {
                const token = await getAccessTokenSilently({
                    audience: 'https://gmkhb.eu.auth0.com/api/v2/',
                    scope: 'update:current_user'
                })

                setAccessToken(token)
            } catch (err) {
                console.log(err)
            }

        }

        getToken()
    }, [getAccessTokenSilently])

    useEffect(() => {
        if (userAuth0Mail === '') return;
        if (userAuth0Mail === authorAuth0Mail) {
            setIsAuthor(true)
        }
    }, [userAuth0Mail, authorAuth0Mail])

    async function fetchContent(slug) {
        const response = await fetchLearningContent(slug);
        const learningContent = response.data
        if (response.error) {
            console.error(response.error);
        } else {
            setAuthorAuth0Mail(learningContent.meta_title)
            setTitle(learningContent.title);
            setPictureSrc(learningContent.feature_image);
            setPictureAlt(learningContent.feature_image_alt);
            let publishDate = dateToString(learningContent.published_at);
            setPublishedAt(publishDate);
            let updateDate = dateToString(learningContent.updated_at);
            setUpdatedAt(updateDate);
            let content = stringToHTMLObj(learningContent.html);
            setContentHTML(content);
        }
    }

    async function fetchAuthor(slug) {
        const response = await fetchPrimaryAuthor(slug);
        const author = response.data

        setAuthorName(author.name);
        setLocation(author.address);
        setProfilePictureSrc(author.pictureSrc);
        setEmail(author.email);
        setWebsite(author.website);
    }

    async function fetchTagList(slug) {
        const loadedTags = await fetchTags(slug);
        if (loadedTags.error) {
            console.error(loadedTags.error);
        } else {
            setTags(loadedTags.data);
        }
    }

    async function deleteContent() {
        let header = {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }

        const response = await deleteLearningContent(slugStr, header)
        history.push('/search')
    }

    return (

        <div className="flex flex-row flex-wrap 2xl:flex-nowrap justify-start">
            <ContentCard className="flex-initial" pictureSrc={pictureSrc} pictureAlt={pictureAlt} title={title} content={contentHTML} publishedAt={publishedAt} updatedAt={updatedAt} tags={tags} isAuthor={isAuthor} delete={deleteContent}/>
            <UserCard className="flex-none" name={authorName} location={location} pictureSrc={profilePictureSrc} email={email} website={website} />
        </div>

    )
}

