import React, { useState, useEffect } from 'react';
import ContentCard from './../../components/contentCard/ContentCard';
import { fetchPage } from './../../services/api.js';
import { stringToHTMLObj } from './../../services/converter.js';

export default function Impressum() {
  {/* States für die Home-Page */ }
  const [title, setTitle] = useState('title');
  const [pictureSrc, setPictureSrc] = useState('src');
  const [pictureAlt, setPictureAlt] = useState('alt');
  const [contentHTML, setContentHTML] = useState({ __html: '' });

  let slug = 'impressum';

  useEffect(() => {
    fetchContent(slug);
  }, [slug]);

  async function fetchContent(slug) {
    const pageContent = await fetchPage(slug);
    if (pageContent.error) {
      console.error(pageContent.error);
    } else {
      setTitle(pageContent.data.title);
      setPictureSrc(pageContent.data.feature_image);
      setPictureAlt(pageContent.data.feature_image_alt);
      let content = stringToHTMLObj(pageContent.data.html);
      setContentHTML(content);
    }
  }
  return (
    <div className="flex justify-center">
      <ContentCard pictureSrc={pictureSrc} pictureAlt={pictureAlt} title={title} content={contentHTML} />
    </div>
  )
}
