import React, { useState } from 'react';
import Tag from '../tag/Tag';
import DeleteModal from '../deleteModal/DeleteModal';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';
import './ContentCard.css';

export default function ContentCard(props) {
    const [openModal, setOpenModal] = useState(false)

    let tagComponents = [];
    if (props.tags != null) {
        const tags = props.tags;
        tagComponents = tags.map((tag) =>
            <Tag key={tag.id} name={tag.name} />
        );
    }

    function toggleModal() {
        setOpenModal(!openModal)
    }


    return (
        <div className="w-full px-4 pt-4 sm:px-6 lg:px-8">
            <div className="mx-auto">
                <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">

                    {/* Header-Teil der ContentCard */}
                    <div className="px-4 py-5 sm:px-6">
                        {props.isAuthor ?
                            (<div className='flex justify-end'>
                                <PencilAltIcon className='text-gray-500 hover:text-green-gmkhb mr-2 flex-shrink-0 h-5 w-5' />
                                <TrashIcon className='text-gray-500 hover:text-red-700 mr-2 flex-shrink-0 h-5 w-5' onClick={() => setOpenModal(true)}/>
                            </div>
                            ) : ''}
                        {props.pictureSrc != null ?
                            (<img
                                className="max-h-52 h-auto w-auto mx-auto"
                                src={props.pictureSrc}
                                alt={props.pictureAlt}
                            />
                            ) : ''}
                        <h1 className="text-2xl font-semibold text-gray-900 text-center mt-4 mb-4">{props.title}</h1>
                        <div className="flex flex-row flex-wrap justify-center my-2">
                            {tagComponents}
                        </div>

                        {/* Zeige Veröffentlichungs- und Aktualisierungsdatum, falls vorhanden */}
                        {props.publishedAt != null ?
                            (<p className="text-sm text-gray-500 italic text-center">Veröffentlicht am {props.publishedAt}</p>
                            ) : ''}
                        {props.updatedAt != null ?
                            (<p className="text-sm text-gray-500 italic text-center">zuletzt aktualisiert am {props.updatedAt}</p>
                            ) : ''}
                    </div>

                    {/* Content-Teil der ContentCard */}
                    <div className="px-4 py-5 sm:p-6">
                        <div dangerouslySetInnerHTML={props.content}></div>
                    </div>
                </div>
            </div>
            <DeleteModal isOpen={openModal} toggleModal={toggleModal} onDeleteClick={props.delete}/>
        </div>
    )
}