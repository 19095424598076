import axios from "axios";

const backendURL = 'https://www.green-meeting-know-how.de/api'

export async function updateUserProfile(id, profileData, header) {
    const response = {
        data: null,
        error: null
    }
    axios.patch(`${backendURL}/users/${id}`, profileData, header)
        .then((response) => {
            response.data = response
        })
        .catch((error) => {
            response.error = error
        })
}

export async function fetchLearningContent(slug) {
    const response = {
        data: null,
        error: null
    };

    let res = await axios.get(`${backendURL}/contents/${slug}`)
    response.data = res.data

    return response;
}

export async function deleteLearningContent(slug, header) {
    const response = {
        data: null,
        error: null
    }
    let res = await axios.delete(`${backendURL}/contents/${slug}`, header)
    response.data = res.data

    return response
}

export async function fetchAllLearningContents() {
    const response = {
        data: null,
        error: null
    };

    let res = await axios.get(`${backendURL}/contents`)
    response.data = res.data.data

    return response;
}

export async function addLearningContent(contentObj, header) {
    let response = {
        data: null,
        error: null
    }
    try {
        response = await axios.post(`${backendURL}/contents`, contentObj, header)
    } catch (err) {
        response.error = err;
    }

    return response;
}

export async function fetchPrimaryAuthor(contentSlug) {
    const response = {
        data: null,
        error: null
    };

    let res = await axios.get(`${backendURL}/contents/${contentSlug}/author`)
    response.data = res.data

    return response;
}

export async function fetchTags(contentSlug) {
    let response = {
        data: null,
        error: null
    };

    try {
        const content = await axios.get(`${backendURL}/contents/${contentSlug}/tags`);
        response = content.data;
    } catch (err) {
        response.error = err;
    }

    return response;
}

export async function fetchPage(slug) {
    const response = {
        data: null,
        error: null
    };

    let res = await axios.get(`${backendURL}/pages/${slug}`)
    response.data = res.data

    return response;
}

export async function addImage(imageFile, header) {
    let response = {
        data: null,
        error: null
    }

    let formData = new FormData()
    formData.append('file', imageFile)

    
    try {
        console.log(formData.get('file'))
        let res = await axios.post(`${backendURL}/images`, formData, header)
        response.data = res.data.data
        response.error = res.error
    } catch (err) {
        response.error = err
    }

    return response
}