import React from 'react'

function Button(props) {
    return (
        <button
            type="button"
            className="px-3 py-2 h-11 shadow-sm border-2 rounded-md border-gray-300 text-green-gmkhb font-medium hover:border-green-gmkhb"
            onClick={props.onClick}
        >
            {props.name}
        </button>
    )
}

export default Button
