import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import BlankUserPic from './../../images/blankUserPicture.png'
import { PencilAltIcon } from '@heroicons/react/outline'
import InputText from '../../components/inputText/InputText';
import InputSubmit from '../../components/inputSubmit/InputSubmit';
import NotificationSuccess from './../../components/notificationSuccess/NotificationSuccess'
import NotificationError from './../../components/notificationError/NotificationError'
import { addImage, updateUserProfile } from '../../services/api';

function Profile() {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [nameDisabled, setNameDisabled] = useState(true);
    const [address, setAddress] = useState('')
    const [addressDisabled, setAddressDisabled] = useState(true)
    const [phone, setPhone] = useState('')
    const [phoneDisabled, setPhoneDisabled] = useState(true)
    const [website, setWebsite] = useState('')
    const [websiteDisabled, setWebsiteDisabled] = useState(true)
    const [imagePath, setImagePath] = useState('')
    const [accessToken, setAccessToken] = useState()
    const [showSuccessNotification, setShowSuccessNotification] = useState(false)
    const [showErrorNotification, setShowErrorNotification] = useState(false)


    useEffect(() => {
        if (user !== undefined) {
            setUserId({ user }.user.sub)
            setName({ user }.user.name)
            setAddress({ user }.user['https://gmkhb.de/user_address'])
            setPhone({ user }.user['https://gmkhb.de/user_phone'])
            setWebsite({ user }.user['https://gmkhb.de/user_website'])
            setImagePath({ user }.user.picture)
        }
    }, [user])

    useEffect(() => {
        const getToken = async () => {

            try {
                const token = await getAccessTokenSilently({
                    audience: 'https://gmkhb.eu.auth0.com/api/v2/',
                    scope: 'update:current_user'
                })

                setAccessToken(token)
            } catch (err) {
                console.log(err)
            }

        }

        getToken()
    }, [getAccessTokenSilently])

    function saveUserProfile(event) {
        event.preventDefault();

        let header = {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }

        let body = {
            name: name,
            picture: imagePath,
            user_metadata: {
                user_address: address,
                user_phone: phone,
                user_website: website
            }
        }

        let response = updateUserProfile(userId, body, header)
        if (response.error) {
            setShowErrorNotification(true)
            console.log(response.error)
        } else {
            setShowSuccessNotification(true)
            console.log(response.data)
        }
    }

    async function handleImageChange() {
        let fileInput = document.getElementById('fileId')

        let header = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            }
        }

        let response = await addImage(fileInput.files[0], header)

        if (response.error) {

        } else {
            setImagePath(response.data.url)
        }
    }

    if (isLoading) return <div>Loading... </div>

    if (!isAuthenticated) {
        return (
            <div className='w-full px-4 py-4 sm:px-6 lg:px-8 flex justify-center'>
                <div className='bg-white overflow-hidden shadow rounded-lg flex flex-col py-4 px-4 text-center text-xl font-semibold'>
                    Hier wird Ihr Profil angezeigt, wenn Sie sich angemeldet haben.
                </div>
            </div>
        );
    }

    if (isAuthenticated) {
        return (
            <div className="px-4 py-4 sm:px-6 lg:px-8 2xl:pl-0">
                {showErrorNotification ? <NotificationError title="Ein Fehler ist aufgetreten." subtext="Das Profil konnte nicht gespeichert werden." /> : ''}
                <NotificationSuccess title="Profil gespeichert" subtext="Ihr Profil wurde erfolgreich gespeichert." show={showSuccessNotification} />
                <div className="max-w-3xl mx-auto">
                    <div className="bg-white overflow-hidden shadow rounded-lg">
                        <form onSubmit={saveUserProfile}>
                            <div className="px-4 py-5 sm:px-6 flex items-end justify-center">
                                <img
                                    id="profileImage"
                                    className="inline h-auto w-1/2 rounded-lg"
                                    src={imagePath}
                                    alt="Userbild"
                                />
                                <input id="fileId" type="file" onChange={handleImageChange} hidden />
                                <PencilAltIcon
                                    className='inline mr-3 flex-shrink-0 h-6 w-6 text-gray-500 hover:text-green-gmkhb'
                                    onClick={() => {
                                        document.getElementById('fileId').click()
                                    }}
                                />
                            </div>
                            <div className='divide-y divide-gray-200 px-4'>
                                <div className='grid grid-cols-10 md:text-lg'>
                                    <div className='md:col-span-1 col-span-2 font-medium py-4'>
                                        <p>Name:</p>
                                    </div>
                                    <div className='md:col-span-8 col-span-7'>
                                        <InputText value={name} disabled={nameDisabled} onChange={event => setName(event.target.value)} />
                                    </div>
                                    <div className="py-4 colspan-1 font-medium justify-self-end">
                                        <PencilAltIcon
                                            className='mr-3 flex-shrink-0 h-6 w-6 text-gray-500 hover:text-green-gmkhb'
                                            onClick={event => {
                                                setNameDisabled(prevDisabled => !prevDisabled)
                                                event.currentTarget.classList.toggle('text-green-gmkhb')
                                            }
                                            } />
                                    </div>
                                </div>
                                <div className='grid grid-cols-10 md:text-lg'>
                                    <div className='md:col-span-1 col-span-2 font-medium py-4'>
                                        <p>E-Mail:</p>
                                    </div>
                                    <div className='md:col-span-8 col-span-7'>
                                        <InputText value={user.email} disabled={true} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-10 md:text-lg'>
                                    <div className='md:col-span-1 col-span-2 font-medium py-4'>
                                        <p>Adresse:</p>
                                    </div>
                                    <div className='md:col-span-8 col-span-7'>
                                        <InputText value={address} disabled={addressDisabled} onChange={event => setAddress(event.target.value)} />
                                    </div>
                                    <div className="py-4 colspan-1 font-medium justify-self-end">
                                        <PencilAltIcon
                                            className='mr-3 flex-shrink-0 h-6 w-6 text-gray-500 hover:text-green-gmkhb'
                                            onClick={event => {
                                                setAddressDisabled(prevDisabled => !prevDisabled)
                                                event.currentTarget.classList.toggle('text-green-gmkhb')
                                            }
                                            } />
                                    </div>
                                </div>
                                <div className='grid grid-cols-10 md:text-lg'>
                                    <div className='md:col-span-1 col-span-2 font-medium py-4'>
                                        <p>Telefon:</p>
                                    </div>
                                    <div className='md:col-span-8 col-span-7'>
                                        <InputText value={phone} disabled={phoneDisabled} onChange={event => setPhone(event.target.value)} />
                                    </div>
                                    <div className="py-4 colspan-1 font-medium justify-self-end">
                                        <PencilAltIcon
                                            className='mr-3 flex-shrink-0 h-6 w-6 text-gray-500 hover:text-green-gmkhb'
                                            onClick={event => {
                                                setPhoneDisabled(prevDisabled => !prevDisabled)
                                                event.currentTarget.classList.toggle('text-green-gmkhb')
                                            }
                                            } />
                                    </div>
                                </div>
                                <div className='grid grid-cols-10 md:text-lg'>
                                    <div className='md:col-span-1 col-span-2 font-medium py-4'>
                                        <p>Webseite:</p>
                                    </div>
                                    <div className='md:col-span-8 col-span-7'>
                                        <InputText value={website} disabled={websiteDisabled} onChange={event => setWebsite(event.target.value)} />
                                    </div>
                                    <div className="py-4 colspan-1 font-medium justify-self-end">
                                        <PencilAltIcon
                                            className='mr-3 flex-shrink-0 h-6 w-6 text-gray-500 hover:text-green-gmkhb'
                                            onClick={event => {
                                                setWebsiteDisabled(prevDisabled => !prevDisabled)
                                                event.currentTarget.classList.toggle('text-green-gmkhb')
                                            }
                                            } />
                                    </div>
                                </div>
                                <div className='flex py-4 justify-center'>
                                    <InputSubmit name="Speichern" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile