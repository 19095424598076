import React, { useRef, useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Checkbox from '../../components/checkbox/Checkbox'
import Textarea from '../../components/textarea/Textarea'
import Editor from './../../components/editor/Editor'
import InputText from './../../components/inputText/InputText'
import Button from './../../components/button/Button'
import NotificationSuccess from './../../components/notificationSuccess/NotificationSuccess'
import NotificationError from './../../components/notificationError/NotificationError'
import Tag from '../../components/tag/Tag'
import { addLearningContent } from '../../services/api'
import InputSubmit from '../../components/inputSubmit/InputSubmit'

export default function Upload() {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const editor = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    }
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('')
    const [tagComponents, setTagComponents] = useState([])
    const [tagInput, setTagInput] = useState('')
    const [tags, setTags] = useState([])
    const [isPath, setIsPath] = useState(false);
    const [showSuccessNotification, setShowSuccessNotification] = useState(false)
    const [showErrorNotification, setShowErrorNotification] = useState(false)
    const [accessToken, setAccessToken] = useState()

    useEffect(() => {
        if (tags.length == 0) {
            setTagComponents([])
        } else {
            setTagComponents(tags.map((tag) =>
                <Tag id={tag} name={tag} />
            ))
        }
    }, [tags])

    useEffect(() => {
        if (tagInput == '') {
            setTags([])
        } else {
            let splittedTags = tagInput.split(' ')
            setTags(splittedTags);
        }
    }, [tagInput])

    useEffect(() => {
        const getToken = async () => {

            try {
                const token = await getAccessTokenSilently({
                    audience: 'https://gmkhb.eu.auth0.com/api/v2/',
                    scope: 'update:current_user'
                })

                setAccessToken(token)
            } catch (err) {
                console.log(err)
            }

        }

        getToken()
    }, [getAccessTokenSilently])

    async function saveContent(event) {
        event.preventDefault();

        let header = {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }

        const htmlContent = editor.current.getContents()
        if (isPath) {
            let tmpTags = tags;
            tmpTags.push('Lernpfad')
            setTags(tmpTags)
        }
        const contentObj = {
            title: { title }.title,
            html: { htmlContent }.htmlContent,
            custom_excerpt: { description }.description,
            tags: { tags }.tags,
            meta_title: { user }.user.email,
            status: "published"
        }

        let response = await addLearningContent(contentObj, header)

        if (response.error) {
            setShowErrorNotification(true)
        } else {
            setShowSuccessNotification(true)
        }
    }

    if (isLoading) return <div>Loading... </div>

    if (isAuthenticated) {
        return (
            <div className='w-full px-4 py-4 sm:px-6 lg:px-8 h-full'>
                {showErrorNotification ? <NotificationError title="Ein Fehler ist aufgetreten" subtext="Der Lerninhalt konnte nicht hochgeladen werden. Bitte laden Sie die Seite neu." /> : ''}
                <NotificationSuccess title="Inhalt gespeichert" subtext="Der Lerninhalt ist nun öffentlich einsehbar" show={showSuccessNotification} />
                <div className='h-full bg-white overflow-hidden shadow rounded-lg flex flex-col py-4 px-4'>
                    <h1 className='md:text-5xl text-3xl font-bold text-gray-900 text-center my-4'>Neuen Inhalt erstellen</h1>
                    <form onSubmit={saveContent}>
                        <InputText id="title" name="Titel" onChange={event => setTitle(event.target.value)} required={true} />
                        <Textarea id="description" name="Beschreibung" rows="4" onChange={event => setDescription(event.target.value)} required={true} />
                        <InputText id="tagInput" name="Tags" onChange={event => setTagInput(event.target.value)} />
                        <div className="flex flex-row flex-wrap justify-center my-2">
                            {tagComponents}
                        </div>
                        <Checkbox id="path" name="Lernpfad" onChange={event => setIsPath(event.target.checked)} description="Es handelt sich um eine Übersichtsseite für zusammenhängende Lerninhalte" />
                        <Editor getInstance={getSunEditorInstance} />
                        <div className='w-4/5 mx-auto'>
                            {/* <Button
                                name="Speichern"
                                onClick={saveContent}
                            /> */}
                            <InputSubmit name="Speichern" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    if (!isAuthenticated) {
        return (
            <div className='w-full px-4 py-4 sm:px-6 lg:px-8 flex justify-center'>
                <div className='bg-white overflow-hidden shadow rounded-lg flex flex-col py-4 px-4 text-center text-xl font-semibold'>
                    Sie müssen sich anmelden, um Inhalte hochladen zu können!
                </div>
            </div>
        );
    }
}
