import React from 'react'

function Tag(props) {
    return (
        <div className="w-min border rounded-full border-green-gmkhb mx-1 whitespace-nowrap">
            <div className="px-2 text-green-gmkhb text-sm">
                {props.name}
            </div>
        </div>
    )
}

export default Tag
