import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { fetchAllLearningContents } from '../../services/api';
import SearchResultList from '../../components/searchResultList/SearchResultList';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';
import { dateToString } from '../../services/converter';

export default function Search() {
    const [allLearningContents, setAllLearningContents] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [contentSearchValue, setContentSearchValue] = useState('');
    const [titleSearchValue, setTitleSearchValue] = useState('');
    const [tagSearchValue, setTagSearchValue] = useState('');
    const [advancedSearch, setAdvancedSearch] = useState(false);

    useEffect(() => {
        fetchLearningContents();
        searchContents();
    }, []);

    useEffect(() => {
        searchContents();
    }, [allLearningContents]);

    async function fetchLearningContents() {
        const learningContents = await fetchAllLearningContents();
        if (learningContents.error) {
            console.log(learningContents.error);
        } else {
            setAllLearningContents(learningContents.data);
            //searchContents()
        }
    }

    function searchContents() {
        let tmpResults = [];
        let results = [];

        /* Filtere alle Lerninhalte nach den jeweiligen Suchstrings */
        tmpResults = allLearningContents.filter(filterContent);
        tmpResults = tmpResults.filter(filterTitle);
        if (tagSearchValue !== '') {
            tmpResults = tmpResults.filter(filterTags);
        }

        /* Erstelle ein Array mit allen nötigen Informationen der passen Lerninhalte */
        tmpResults.map((learningContent) => {
            let published = dateToString(learningContent.published_at);
            let updated = dateToString(learningContent.updated_at);
            results.push(
                {
                    title: learningContent.title,
                    content: learningContent.custom_excerpt,
                    tags: learningContent.tags,
                    publishedAt: published,
                    updatedAt: updated,
                    slug: learningContent.slug
                }
            )
        });

        setSearchResults(results);
    }

    /* Filter für den Inhalt des Lerninhalts */
    function filterContent(content) {
        content = content.html.toLowerCase();
        let contentSearchString = contentSearchValue.toLowerCase();

        return content.includes(contentSearchString);
    }

    /* Filter für den Titel des Lerninhalts */
    function filterTitle(content) {
        let title = content.title.toLowerCase();
        let titleSearchString = titleSearchValue.toLowerCase();

        return title.includes(titleSearchString);
    }

    /* Filter für die Tags des Lerninhalts */
    function filterTags(content) {
        let tags = content.tags;
        let tagSearchString = tagSearchValue.toLowerCase();

        return tags.some(tag => tag.name.toLowerCase() === tagSearchString);
    }

    return (
        <div>
            <div className="mx-auto px-4 pt-4 sm:px-6 lg:px-8">
                <div className="mx-auto">
                    <div className="bg-white overflow-hidden shadow rounded-lg ">
                        <h1 className="text-2xl font-semibold text-gray-900 text-center mt-4">Suche</h1>
                        <div className="max-w-3xl mx-auto">
                            <InputText id="title" name="Titel" onChange={event => setTitleSearchValue(event.target.value)} />
                            {!advancedSearch ?
                                (
                                    <div className="flex content-center">
                                        <button
                                            className="inline-flex items-center mx-auto relative text-gray-500 text-sm hover:text-green-gmkhb"
                                            onClick={() => setAdvancedSearch(true)}>
                                            <ChevronDownIcon className="w-4" />
                                            <span>erweiterte Suche</span>
                                        </button>
                                    </div>
                                ) : ''}
                            {advancedSearch ?
                                (
                                    <InputText id="content" name="Inhalt" onChange={event => setContentSearchValue(event.target.value)} />
                                ) : ''}
                            {advancedSearch ?
                                (
                                    <InputText id="tag" name="Tag" onChange={event => setTagSearchValue(event.target.value)} />
                                ) : ''}
                            <div className="my-4 flex justify-center items-center">
                                <Button
                                    name="Suchen"
                                    onClick={searchContents}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SearchResultList results={searchResults} />
        </div>
    )
}
