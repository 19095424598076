import React from 'react'
import Tag from '../tag/Tag';
import { Link } from 'react-router-dom';

function SearchResultItem(props) {
    const tags = props.result.tags.map((tag) =>
        <Tag key={tag.id} name={tag.name} />
    );
    
    let updated = props.result.updatedAt;
    let ref = "/content/" + props.result.slug;
    
    return (
        <div className="w-full">
            <Link to={ref}><h3 className="px-4">{props.result.title}</h3></Link>
            <p className="text-sm text-gray-500 italic text-left px-4 mb-2">Veröffentlicht am {props.result.publishedAt} {updated != null ? ("- zuletzt aktualisiert am " + updated) : ''}</p>
            <p className="px-4 text-sm text-gray-500">{props.result.content}</p>
            <div className="flex flex-row flex-wrap justify-left my-2 px-3">
                {tags}
            </div>

            
        </div>
    )
}

export default SearchResultItem
