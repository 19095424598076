import React from 'react';
import { LocationMarkerIcon, IdentificationIcon, MailIcon, ExternalLinkIcon } from '@heroicons/react/outline';
import BlankUserPic from './../../images/blankUserPicture.png'

function UserCard(props) {
    let profileImage = props.pictureSrc;

    return (
        <div className="w-96 px-4 py-4 sm:px-6 lg:px-8 2xl:pl-0">
            <div className="max-w-3xl mx-auto">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <img
                            className="h-auto w-2/3 rounded-lg mx-auto"
                            src={props.pictureSrc === null ? BlankUserPic : profileImage}
                            alt="Userbild"
                        />
                        <h3 className="my-4 text-gray-900 text-sm font-medium text-center">{props.name}</h3>

                        {props.location != null ?
                            (<div className="text-gray-500 text-sm flex"><LocationMarkerIcon className='mr-2 flex-shrink-0 h-5 w-5' />{props.location}</div>
                            ) : ''}
                        {props.email != null ?
                            (<a href={`mailto:${props.email}`} className="text-gray-500 text-sm flex hover:text-green-gmkhb"><MailIcon className='mr-2 flex-shrink-0 h-5 w-5' />{props.email}</a>
                            ) : ''}
                        {props.website != null ?
                            (<a href={props.website} className="text-gray-500 text-sm flex hover:text-green-gmkhb"><ExternalLinkIcon className='mr-2 flex-shrink-0 h-5 w-5' />{props.website}</a>
                            ) : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserCard
