import { useState } from 'react'
import Navbar from '../../parts/navbar/Navbar';
import Header from '../../parts/header/Header';
import Home from '../../pages/home/Home';
import Search from '../../pages/search/Search';
import Upload from '../../pages/upload/Upload';
import LearningContent from '../../pages/learningContent/LearningContent';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Footer from '../../parts/footer/Footer';
import Impressum from '../../pages/impressum/Impressum';
import Datenschutz from '../../pages/datenschutz/Datenschutz';
import Profile from '../../pages/profile/Profile';


export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <div className="flex flex-grow overflow-hidden bg-gray-100">
          <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <Header setSidebarOpen={setSidebarOpen} />
            <main className="flex-1 relative overflow-y-auto focus:outline-none">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/search">
                  <Search />
                </Route>
                <Route path="/upload">
                  <Upload />
                </Route>
                <Route path="/content/:slug">
                  <LearningContent />
                </Route>
                <Route path="/impressum">
                  <Impressum />
                </Route>
                <Route path="/datenschutz">
                  <Datenschutz />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
              </Switch>
            </main>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  )
}