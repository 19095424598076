import React from 'react'

function InputText(props) {
    return (
        <div className="my-4 mx-auto relative rounded-md shadow-sm w-4/5">
            <label
                htmlFor={props.id}
                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
            >
                {props.name}
            </label>
            {props.required ?
                (<input
                    type="text"
                    name={props.name}
                    id={props.id}
                    className="focus:ring-green-gmkhb focus:border-green-gmkhb block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    value={props.value}
                    disabled={props.disabled}
                    required
                />
                ) : (
                    <input
                        type="text"
                        name={props.name}
                        id={props.id}
                        className="focus:ring-green-gmkhb focus:border-green-gmkhb block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={props.placeholder}
                        onChange={props.onChange}
                        value={props.value}
                        disabled={props.disabled}
                    />
                )}
        </div>
    )
}

export default InputText
