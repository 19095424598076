import React from 'react';
import SunEditor from 'suneditor-react'
import plugins, { image } from 'suneditor/src/plugins'
import 'suneditor/dist/css/suneditor.min.css'
import 'suneditor/src/lang/de.js'
import insertPDF from './InsertPDFPlugin';

function Editor(props) {
    
    return (
        <div className="my-4 mx-auto relative w-4/5">
            <SunEditor getSunEditorInstance={props.getInstance} lang="de" setOptions={{
                "textTags": {
                    "bold": "b",
                    "underline": "u",
                    "italic": "i",
                    "strike": "s"
                },
                "mode": "classic",
                "rtl": false,
                "katex": "window.katex",
                "height": "auto",
                "minHeight": "500px",
                "defaultStyle": "font-family: Arial; font-size: 16px",
                "fontSize": [
                    8,
                    10,
                    14,
                    18,
                    24,
                    36
                ],
                "formats": [
                    "p",
                    "blockquote",
                    "h1",
                    "h2",
                    "h3"
                ],
                "colorList": [
                    [
                        "#ff0000",
                        "#ff5e00",
                        "#ffe400",
                        "#abf200"
                    ],
                    [
                        "#00d8ff",
                        "#0055ff",
                        "#6600ff",
                        "#ff00dd"
                    ]
                ],
                "videoFileInput": false,
                "hrItems": [
                    {
                        "name": "Outset",
                        "style": "border-style: outset;"
                    }
                ],
                "tabDisable": false,
                plugins: {
                    ...plugins,
                    insertPDF
                },
                "buttonList": [
                    [
                        "undo",
                        "redo",
                        "fontSize",
                        "formatBlock",
                        "blockquote",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "fontColor",
                        "hiliteColor",
                        "removeFormat",
                        "outdent",
                        "indent",
                        "align",
                        "horizontalRule",
                        "list",
                        "table",
                        "link",
                        "PDF_einfügen",
                        "image",
                        "video",
                        "audio",
                        "fullScreen",
                        "showBlocks",
                        "preview",
                        "print"
                    ]
                ]
            }} />
        </div>
    );
}

export default Editor;
