import React from 'react';

function Textarea(props) {
    return (
        <div className='my-4 mx-auto relative rounded-md shadow-sm w-4/5'>
            <label
                htmlFor={props.id}
                className="absolute -top-2 left-2 -mt-px px-1 bg-white text-xs font-medium text-gray-900">
                {props.name}
            </label>
            {props.required ?
                (<textarea
                    id={props.id}
                    name={props.name}
                    rows={props.rows}
                    className="focus:ring-green-gmkhb focus:border-green-gmkhb block w-full sm:text-sm border-gray-300 rounded-md"
                    defaultValue={''}
                    onChange={props.onChange}
                    required
                />
                ) : (
                    <textarea
                        id={props.id}
                        name={props.name}
                        rows={props.rows}
                        className="focus:ring-green-gmkhb focus:border-green-gmkhb block w-full sm:text-sm border-gray-300 rounded-md"
                        defaultValue={''}
                        onChange={props.onChange}
                    />
                )}
        </div>
    );
}

export default Textarea;
