/* This example requires Tailwind CSS v2.0+ */
import { XCircleIcon } from '@heroicons/react/solid'

export default function Example(props) {
    return (
        <div className="rounded-md bg-red-50 p-4 mb-4 shadow">
            <div className="flex items-start">
                <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="mt-0 text-sm font-medium text-red-800">{props.title}</h3>
                    <div className="mt-2 text-sm text-red-700">
                        <p>{props.subtext}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
