import React from 'react';
import ImageVideo from './../../videos/Imagevideo.mp4'
import LogoUOS from './../../images/logo_UOS.jpg'
import LogoIMWI from './../../images/logo_IMWI.png'
import LogoDBU from './../../images/logo_DBU.png'
import AbbildungDigitaleMenschen from './../../images/digitaleMenschen.png'
import ErdeNachhaltig from './../../images/ErdeNachhaltig.jpg'
import Devices1 from './../../images/devices1.png'
import Devices2 from './../../images/devices2.png'
import EBookReader from './../../images/ebookReader.jpg'
import Professor from './../../images/professor.jpg'
import Student from './../../images/student.jpg'
import Manager from './../../images/manager.jpg'
import CloudStorage from './../../images/cloudStorage.jpg'
import Tablet from './../../images/tablet.jpg'

export default function Home() {
  return (
    <div className="w-full px-4 py-4 sm:px-6 lg:px-8">
      <div className='bg-white overflow-hidden shadow rounded-lg flex flex-col justify-center py-4 px-4 divide-y divide-gray-200'>
        <h1 className='md:text-5xl text-3xl font-bold text-gray-900 text-center my-4'>Willkommen auf der Green Meeting Know-how Box</h1>
        <div className='flex justify-center'>
          <video controls className='w-auto py-12'>
            <source src={ImageVideo} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='grid grid-cols-3 justify-items-center'>
          <img src={LogoUOS} className='inline w-full max-w-lg pt-12 2xl:ml-12' alt='Logo der Universität Osnabrück'></img>
          <img src={LogoIMWI} className='inline w-full max-w-lg pt-12' alt='Logo des Fachgebiets Informationsmanagement und Wirtschaftsinformatik (IMWI) der Universität Osnabrück'></img>
          <img src={LogoDBU} className='inline w-full max-w-lg pt-12' alt='Logo der Deutschen Bundesstifutng Umwelt (DBU)'></img>
          <p className='col-span-3 py-4 text-gray-900 xl:text-3xl md:text-4xl text-xl text-center 2xl:mx-48'>Die GMKHB wird momentan im Rahmen eines DBU-Projektes an der Universität Osnabrück am Fachbereich Informationsmanagement und Wirtschaftsinformatik entwickelt mit dem Ziel:</p>
        </div>
        <div className='grid 2xl:grid-cols-3'>
          <p className='col-span-2 py-4 text-gray-900 xl:text-3xl md:text-4xl text-xl text-right my-auto'>Entwickelung einer Multiplikatoren-Plattform für ein nachhaltiges und digitales Eventmanagement.</p>
          <img src={AbbildungDigitaleMenschen} className='2xl:col-span-1 col-span-2 2xl:max-w-sm max-w-xs mx-auto rounded-3xl my-4' alt='Abbildung mehrere Menschen in einer digitalen Welt.'></img>
          <img src={EBookReader} className='2xl:col-span-1 col-span-2 2xl:max-w-sm max-w-xs mx-auto rounded-3xl my-4' alt='Abbildung eines Smartphones, das in ein Buch übergeht.'></img>
          <p className='col-span-2 text-gray-900 xl:text-3xl md:text-4xl text-xl my-auto'> Hier ist die Anlaufstelle für wissbegierige Menschen aus der Veranstaltungsbranche. Durch eine digitale Bibliothek bietet die Plattform eine moderne Möglichkeit der Begegnung an.
          </p>
          <p className='col-span-2 text-gray-900 xl:text-3xl md:text-4xl text-xl text-right 2xl:my-auto mt-8'>
            Verschiedene Akteure und Organisationen der Branche können hier Inhalte rund um die Themen Nachhaltigkeit und Digitalisierung nachlesen und auch selbst publizieren.
          </p>
          <img src={ErdeNachhaltig} className='2xl:col-span-1 col-span-2 2xl:max-w-sm max-w-xs mx-auto rounded-3xl my-4' alt='Darstellung einer nachhalitgen Weltkugel'></img>
        </div>
        <div className='grid grid-cols-3 justify-items-center items-center'>
          <p className='col-span-3 mt-4 text-gray-900 xl:text-3xl md:text-4xl text-xl text-center 2xl:mx-48 font-medium'>Für wen ist die Plattform gedacht? … </p>
          <img src={Professor} className='w-full xl:max-h-96 max-h-72 max-w-xl pt-12 pl-4' alt='Schematische Darstellung eines Lehrenden'></img>
          <img src={Student} className='w-full xl:max-h-96 max-h-72 max-w-xl pt-12 px-2' alt='Schematische Darstellung eines Lernenden'></img>
          <img src={Manager} className='w-full xl:max-h-96 sm:max-h-72  max-w-xl pt-12 pr-4' alt='Schematische Darstellung eines Praktikers'></img>
          <p className='col-span-1 pb-4 text-gray-900 xl:text-3xl md:text-4xl text-xl text-center 2xl:mx-48 font-medium'>Lehrende</p>
          <p className='col-span-1 pb-4 text-gray-900 xl:text-3xl md:text-4xl text-xl text-center 2xl:mx-48 font-medium'>Lernende</p>
          <p className='col-span-1 pb-4 text-gray-900 xl:text-3xl md:text-4xl text-xl text-center 2xl:mx-48 font-medium'>Praktiker</p>
        </div>
        <div className='grid grid-cols-2'>
          <img src={CloudStorage} className='lg:max-h-96 lg:w-auto inline w-full mx-auto px-2 mt-4 rounded-3xl' alt='Favoriten Icon'></img>
          <img src={Tablet} className='lg:w-auto lg:max-h-96 w-full mx-auto mt-4 rounded-3xl' alt='Abbildung eines Buchs mit einem Vorhängeschloss'></img>
          <p className='text-gray-900 xl:text-3xl md:text-4xl text-xl text-center mt-4 '><u>Ohne Registrierung</u> kannst du auf der Plattform kostenlos surfen und alle Inhalte einsehen.</p>
          <p className='text-gray-900 xl:text-3xl md:text-4xl text-xl text-center mt-4'><u>Mit Registrierung</u> kannst du als zusätzliche Funktion, eigene Inhalte hochladen und favorisierte Inhalte speichern.</p>
          <p className='col-span-2 mt-8 text-gray-900 xl:text-3xl md:text-4xl text-xl text-center 2xl:mx-48'>Und das beste an der ganzen Sache ist… </p>
          <img src={Devices1} className='2xl:col-span-1 max-h-96 object-scale-down col-span-2 inline w-full mx-auto mt-4' alt='Favoriten Icon'></img>
          <img src={Devices2} className='2xl:col-span-1 max-h-96 object-scale-down col-span-2 inline w-full mx-auto mt-4' alt='Favoriten Icon'></img>
          <p className='col-span-2 py-4 text-gray-900 xl:text-3xl md:text-4xl text-xl text-center 2xl:mx-48'>du kannst die Plattform auf allen gängigen Endgeräten abrufen.</p>
        </div>
      </div>
    </div>
  )
}
