import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { MenuAlt2Icon } from '@heroicons/react/outline';
import Button from '../../components/button/Button';
import ProfileDropdown from '../../components/profileDropdown/ProfileDropdown';

function Header({ setSidebarOpen }) {
    const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

    return (
        <div>
            {/* Header Bar */}
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
                <button
                    className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex justify-end items-center w-full px-4">
                    {!isAuthenticated && (
                        <Button
                            name="Anmelden"
                            onClick={loginWithRedirect}
                        />
                    )}
                    {isAuthenticated && (
                        <ProfileDropdown onClick={logout} pictureSrc={user.picture}/>
                    )}
                </div>
            </div>
            {/* /End Header Bar */}
        </div>

    )
}

export default Header;


