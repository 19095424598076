import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <Auth0Provider
    domain="gmkhb.eu.auth0.com"
    clientId="U6zlNNGZXbPfwc9Q3j0WI8NVNK5kI9Qr"
    redirectUri={window.location.origin}
    audience='https://gmkhb.eu.auth0.com/api/v2/'
    scope='update:current_user'
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
